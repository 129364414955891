import {
    UserFactory,
    Searcher,
    ProductSearchBuilder,
    DataValueFactory,
    DataValue,
    ProductResult
    // ContentSearchBuilder,
    // SearchCollectionBuilder,
    // SearchTermPredictionBuilder,
    // FilterBuilder,
    // StringDataValue,
    // ProductSortingBuilder,
} from "@relewise/client";
import { useInfiniteQuery } from "@tanstack/react-query";
import useFacets, { IActiveFacet } from '~/components/features/ProductsList/Facets/use-facets';
import { isJPG, getRelewiseKey, getRelewiseDataset, getRelewiseAPIUrl } from 'utils';
import { useUserInformation } from '~/page-type/page-types/P181Profile';
import { useRouter } from "next/router";
import { useAuthentication } from "~/shared/hooks";
import { useMemo } from "react";

// const API_URL = 'https://api.relewise.com';
const API_URL = getRelewiseAPIUrl()
const DATASET_ID = getRelewiseDataset();
const AUTHORIZATION = getRelewiseKey();

const searcher = new Searcher(DATASET_ID, AUTHORIZATION, {
    serverUrl: API_URL
});



type RelewisePartCriteriaItem = {
    data?: {
        CritNo: DataValue;
        CritValue: DataValue;
        CriteriaDesc: DataValue;
        CriteriaValueDesc: DataValue;
        SortNo: DataValue;

    }
}

interface RelewisePartCriterias extends DataValue {
    value: {
        $values: RelewisePartCriteriaItem[]
    }
}

type StringListDataValue = DataValue & {
    type: 'StringList'
    value: {
        $values: string[]
    }
}

export type IStatusCodeColor =
    | '0' // red
    | '1' // green
    | '2' // greenWarehouse
    | '3' // yellow


interface TrafficLight extends DataValue {
    value: IStatusCodeColor
}



export interface RelewiseProduct extends ProductResult {
    displayName: string;
    productId: string;
    brand: {
        id: string;
        displayName: string;
        rank: number;
    }
    data?: {
        PictureWebFull?: DataValue;
        Description?: DataValue;
        ExpectedDate?: DataValue;
        WebItemName?: DataValue;
        Unit?: DataValue;
        PackingUnit?: DataValue;
        Remark?: DataValue;
        OldJPNumber?: DataValue;
        NameAlias?: DataValue;
        SuitableForExpres?: DataValue;
        DepthModifiedDateTime?: DataValue;
        HowStock?: DataValue;
        HowTrafficLight?: TrafficLight;
        JpgStock?: DataValue;
        ShowJpStock?: DataValue;
        JpgTrafficLight?: TrafficLight;
        PartCriterias?: RelewisePartCriterias;
        JpGroupNumber?: DataValue;
        NewMonth?: DataValue;
        Barcode?: DataValue;
        Depth?: DataValue;
        Height?: DataValue;
        Width?: DataValue;
        ReferenceIds?: StringListDataValue;
    };
};


export interface IRelewiseDataFilter {
    key: string;
    values: string[];
}

export type SortKeyType = 'JpGroupNumber' | ''
export type SortOrderType = 'Ascending' | 'Descending'

interface ICommonProps {
    shouldFetch?: boolean;
    pageSize?: number;
    searchQuery?: string;
    productCategories?: string[];
    sortBy?: SortKeyType
    sortOrder?: SortOrderType
    productIdFilter?: string[]
    brandFilter?: string[]
    remarkFilter?: string[]
    manufacturerFilter?: string[]
    vehicleTypeFilter?: string[]
    modelSeriesFilter?: string[],
    modelVersionFilter?: string[],
    vehicleTypeNumberFilter?: number[],
    dataFilter?: IRelewiseDataFilter[] // Filter based on product data
    includeFacets?: boolean;
    newMonthFilter?: string[];
}

export interface IFetchRelewiseProps extends ICommonProps {
    activeFacets: IActiveFacet[]
    page?: number;
    disallowedProductsGroups: string[];
    locale: string;
}

const isJPGroup = isJPG()

export const relewiseFetcher = async (props: IFetchRelewiseProps) => {

    const {
        searchQuery,
        page = 1,
        pageSize,
        productCategories,
        sortBy,
        sortOrder = 'Ascending',
        brandFilter,
        productIdFilter,
        manufacturerFilter,
        vehicleTypeFilter,
        modelSeriesFilter,
        modelVersionFilter,
        vehicleTypeNumberFilter,
        newMonthFilter,
        dataFilter,
        activeFacets,
        includeFacets,
        locale,
        disallowedProductsGroups
    } = props


    const selectedBrandFacet = activeFacets?.find(f => f.key === 'Brand')?.values || []
    const selectedCategoryFacet = activeFacets?.find(f => f.key === 'Category')?.values || []
    const selectedManufacturerFacet = activeFacets?.find(f => f.key === 'Manufacturer')?.values || []
    const selectedBodyTypeFacet = activeFacets?.find(f => f.key === 'BodyType')?.values || []
    const selectedVehicleTypeFacet = activeFacets?.find(f => f.key === 'VehicleType')?.values || []
    const selectedModelSeriesFacet = activeFacets?.find(f => f.key === 'ModelSeries')?.values || []
    const selectedModelVersionFacet = activeFacets?.find(f => f.key === 'ModelVersion')?.values || []
    // const selectedRemarkFacet = activeFacets?.find(f => f.key === 'Remark')?.values || []
    const selectedFuelTypeFacet = activeFacets?.find(f => f.key === 'FuelType')?.values || []
    const selectedProductionYearsFacet = activeFacets?.find(f => f.key === 'ProductionYears')?.values || []
    const selectedIsClassicFacet = activeFacets?.find(f => f.key === 'IsClassic')?.values
        ?.map(v => v === 'true' ? true : false) || []
    const selectedCategoryHierarchyFacet = activeFacets?.find(f => f.key === 'CategoryHierarchy')?.values || []
    const selectedNewMonthFacet = activeFacets?.find(f => f.key === 'NewMonth')?.values || []
    const settings = {
        language: locale,
        currency: "DKK",
        displayedAtLocation: "search page",
        user: UserFactory.anonymous()
    };

    const requestBodyBuilder = new ProductSearchBuilder(settings)
        .setSelectedProductProperties({
            displayName: true,
            pricing: true,
            brand: true,
            categoryPaths: true,

            // Specify only the needed data keys to reduce the response time or use
            // allData: true,
            // allVariants: true,
            dataKeys: [
                'PartCriterias',
                'PictureWebFull',
                'OldJPNumber',
                'Unit',
                'PackingUnit',
                'JpgStock',
                'JpgTrafficLight',
                'HowStock',
                'HowTrafficLight',
                'ShowOnJPG',
                'ShowOnHOW',
                'JpGroupNumber',
                'ExpectedDate',
                'Remark',
                'SuitableForExpres',
                'NewMonth',
                'ShowJpStock',
                'Barcode',
                'Depth',
                'Height',
                'Width',
                'ReferenceIds'
                // 'Linkages' // remove
            ],
        })
        .setTerm(searchQuery)
        .pagination(p => p
            .setPageSize(pageSize || 0)
            .setPage(page))

        // Facets
        // The array of the available facets will be in the same order they are called
        .facets(facet => {
            if (includeFacets) {
                facet.addProductDataObjectFacet("Linkages", "Product", o => {
                    o.addBooleanFacet("IsClassic", selectedIsClassicFacet)
                    o.addStringFacet("Manufacturer", selectedManufacturerFacet)
                    o.addStringFacet("ModelSeries", selectedModelSeriesFacet)
                    o.addStringFacet("ModelVersion", selectedModelVersionFacet)
                    o.addStringFacet("ProductionYears", selectedProductionYearsFacet, "And")
                    o.addStringFacet("BodyType", selectedBodyTypeFacet)
                    o.addStringFacet("VehicleType", selectedVehicleTypeFacet)
                    o.addStringFacet("FuelType", selectedFuelTypeFacet)
                })
                facet.addProductDataStringValueFacet("NewMonth", "Product", selectedNewMonthFacet)
                facet.addBrandFacet(selectedBrandFacet)
                facet.addCategoryFacet('Ancestors', selectedCategoryFacet)
                facet.addProductCategoryHierarchyFacet(
                    "ImmediateParent",
                    selectedCategoryHierarchyFacet?.map((hierarchyFacet) => {
                        const ids = hierarchyFacet.split("-");

                        return {
                            breadcrumbPathStartingFromRoot: [
                                { id: ids[0] },
                                { id: ids[1] }
                            ],
                        }
                    }) || null,
                    { displayName: true }
                )
            }
            return facet
        })

        // sorting
        .sorting(sort => {
            if (sortBy) {
                sort.sortByProductData(sortBy, 'Product', sortOrder)
            } else {
                sort.sortByProductRelevance()
            }
            return sort
        })

        // Filters
        .filters(filter => {
            filter.addProductDataFilter(
                isJPGroup ? 'ShowOnJPG' : 'ShowOnHOW', conditions => conditions
                    .addEqualsCondition(DataValueFactory.boolean(true))
            )
            if (productCategories?.length) {
                filter.addProductCategoryIdFilter('ImmediateParent', productCategories)
            }
            if (brandFilter?.length) {
                filter.addBrandIdFilter(brandFilter)
            }

            if (productIdFilter?.length) {
                filter.addProductIdFilter(productIdFilter)
            }

            if (disallowedProductsGroups?.length) {
                filter.addProductDataFilter("StatGroupId", c => c.addContainsCondition(
                    DataValueFactory.stringCollection(disallowedProductsGroups),
                    undefined,
                    true
                )
                );
            }

            // Added filters for optimization with flattened keys
            if (selectedBodyTypeFacet?.length) {
                filter.addProductDataFilter("BodyType", c => c.addContainsCondition(DataValueFactory.stringCollection(selectedBodyTypeFacet), 'Any'))
            }

            if (selectedModelSeriesFacet?.length) {
                filter.addProductDataFilter("ModelSeries", c => c.addContainsCondition(DataValueFactory.stringCollection(selectedModelSeriesFacet), 'Any'))
            }

            if (selectedProductionYearsFacet?.length) {
                filter.addProductDataFilter("ProductionYears", c => c.addContainsCondition(DataValueFactory.stringCollection(selectedProductionYearsFacet), 'Any'))
            }

            if (selectedIsClassicFacet?.length) {
                filter.addProductDataFilter("IsClassic", c => c.addContainsCondition(DataValueFactory.booleanCollection(selectedIsClassicFacet), 'Any'))
            }

            if (selectedManufacturerFacet?.length) {
                filter.addProductDataFilter("Manufacturer", c => c.addContainsCondition(DataValueFactory.stringCollection(selectedManufacturerFacet), 'Any'))
            }

            if (selectedModelVersionFacet?.length) {
                filter.addProductDataFilter("ModelVersion", c => c.addContainsCondition(DataValueFactory.stringCollection(selectedModelVersionFacet), 'Any'))
            }

            if (selectedVehicleTypeFacet?.length) {
                filter.addProductDataFilter("VehicleType", c => c.addContainsCondition(DataValueFactory.stringCollection(selectedVehicleTypeFacet), 'Any'))
            }

            if (selectedFuelTypeFacet?.length) {
                filter.addProductDataFilter("FuelType", c => c.addContainsCondition(DataValueFactory.stringCollection(selectedFuelTypeFacet), 'Any'))
            }
            if (selectedNewMonthFacet?.length) {
                filter.addProductDataFilter("NewMonth", c => c.addContainsCondition(DataValueFactory.stringCollection(selectedNewMonthFacet), 'Any'))
            }

            if (dataFilter?.length) {
                dataFilter.forEach(el => {
                    if (el.values?.length) {
                        filter.or(or => {
                            el.values?.forEach(v => {
                                or.addProductDataFilter(
                                    el.key, cond => cond.addEqualsCondition(DataValueFactory.string(v))
                                );
                            })
                        })
                    }
                });
            }

            // Vehicle filters
            if (
                manufacturerFilter?.length
                || vehicleTypeFilter?.length
                || modelSeriesFilter?.length
                || modelVersionFilter?.length
                || vehicleTypeNumberFilter?.length
                || newMonthFilter?.length
            )
                filter.addProductDataFilter('Linkages', f => {
                    f.addDataObjectCondition(cond => {
                        if (manufacturerFilter?.length) {
                            cond.addEqualsCondition('Manufacturer', DataValueFactory.stringCollection(manufacturerFilter))
                        }
                        if (vehicleTypeFilter?.length) {
                            cond.addEqualsCondition('VehicleType', DataValueFactory.stringCollection(vehicleTypeFilter))
                        }
                        if (modelSeriesFilter?.length) {
                            cond.addEqualsCondition('ModelSeries', DataValueFactory.stringCollection(modelSeriesFilter))
                        }
                        if (modelVersionFilter?.length) {
                            cond.addEqualsCondition('ModelVersion', DataValueFactory.stringCollection(modelVersionFilter))
                        }
                        if (vehicleTypeNumberFilter?.length) {
                            cond.addEqualsCondition('VehicleTypeNumber', DataValueFactory.doubleCollection(vehicleTypeNumberFilter))
                        }
                        if (newMonthFilter?.length) {
                            cond.addEqualsCondition('NewMonth', DataValueFactory.stringCollection(newMonthFilter))

                        }

                        // FOR documentation purposes !!!!

                        // cond.addEqualsCondition('VehicleTypeNumber', DataValueFactory.number(6203))
                        // cond.addEqualsCondition('Manufacturer', DataValueFactory.string('VOLVO'))
                        // cond.addContainsCondition('ProductionYears', DataValueFactory.stringCollection(['1959', '1972', '2020']), 'Any')
                        // cond.addInRangeCondition('Bhp', { lowerBoundInclusive: 50, upperBoundInclusive: 80 })
                    })
                })


            return filter
        });

    const request = await searcher.searchProducts(requestBodyBuilder.build());
    return request
}


export const useRelewiseData = (props: ICommonProps) => {
    const { status } = useAuthentication();

    const {
        pageSize = 30,
        searchQuery,
        shouldFetch = true,
        includeFacets = true,
        ...rest
    } = props

    const { locale, defaultLocale } = useRouter();


    const { data: userInformation } = useUserInformation()

    const disallowedProductsGroups = userInformation?.disallowedProductsGroups || []

    const { activeFacets } = useFacets()

    const queryKey = [
        'relewise productList',
        searchQuery,
        [...activeFacets],
        { ...rest },
        { ...disallowedProductsGroups }
    ]

    const triggerFetch = useMemo(
        () => {
            if (status === 'authenticated') {
                return shouldFetch && userInformation !== undefined;
            } else {
                return shouldFetch;
            }
        },
        [status, shouldFetch, userInformation]
    );

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        isFetching
    } = useInfiniteQuery({
        queryKey,
        queryFn: ({ pageParam = 1 }) => relewiseFetcher({
            activeFacets,
            disallowedProductsGroups,
            pageSize,
            page: pageParam,
            locale: locale || defaultLocale || '',
            includeFacets,
            searchQuery,
            ...rest
        }),
        keepPreviousData: true,
        getNextPageParam: (lastPage, pages) => {
            const hits = lastPage?.hits || 0
            const totalPages = Math.ceil(hits / pageSize)
            const loadedPagesLength = pages?.length || 1

            if (pages?.length >= totalPages) {
                return undefined
            }
            return loadedPagesLength + 1
        },
        enabled: triggerFetch,
        staleTime: 1000 * 60 * 60, // 1 hour
        cacheTime: 1000 * 60 * 60, // 1 hour
    })

    const products = data?.pages?.map(p => p?.results).flat() as RelewiseProduct[] || [];

    return {
        totalProducts: data?.pages[0]?.hits || 0,
        products,
        facets: data?.pages[0]?.facets || null,
        isError: error,
        isLoading,
        isFetching,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    }
}


