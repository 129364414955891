import { Skeleton, Text, getSkeletonRandom, Accordion, AccordionItem } from 'ui';

import styles from './SidebarFilter.module.css';
import { useTranslations } from '~/services/cms';
import { CategoryHierarchyFacetResult, CategoryHierarchyFacetResultCategoryNode } from '@relewise/client';
import { FC, useMemo } from 'react';
import { useRouter } from 'next/router';
import useFacets from '~/components/features/ProductsList/Facets/use-facets';
import { Checkbox } from 'ui';
import { weakKey } from 'utils';

interface IProps {
    facets?: CategoryHierarchyFacetResult
    isFetching?: boolean;
    isLoading?: boolean;
}

const SkeletonListItem = () => {
    const width = useMemo(() => getSkeletonRandom(60), [])

    return (
        <li className={styles.skeletonListItem}>
            <Skeleton
                width={`${width}%`} height='25px'
            />
            <Skeleton
                width='25px' height='25px'
            />
        </li>
    )
}

const SkeletonList = () => (
    <>
        {
            Array.from(Array(10).keys())
                .map((item) => (
                    <SkeletonListItem key={item} />
                ))
        }
    </>
)

const sortCategories = (
    a: CategoryHierarchyFacetResultCategoryNode,
    b: CategoryHierarchyFacetResultCategoryNode
) => {
    {
        if (!a?.category?.displayName || !b?.category?.displayName)
            return 0;

        if (a?.category?.displayName < b?.category?.displayName) {
            return -1;
        }
        if (a?.category?.displayName > b?.category?.displayName) {
            return 1;
        }
        return 0;
    }
}

export const SidebarFilter: FC<IProps> = ({ facets, isFetching, isLoading }) => {
    const translate = useTranslations()
    const { setCategoryHierarchyFacet, setAllFacetsInCategory } = useFacets()

    const { query } = useRouter();

    const searchQuery = query?.query as string || ''

    return (
        <div>
            <div className={styles.header}>
                <Text variant="body">
                    {translate('product.productCategories')}
                </Text>
            </div>
            <Accordion type='single'>
                {isLoading
                    ? <SkeletonList />
                    : facets?.nodes?.sort(sortCategories).map((f, index) => {
                        const selectedChildren = f.children?.filter(option => option.selected) || [];
                        const allSelected = f.children?.length === selectedChildren.length;

                        return (
                            <div className={styles.headerWrapper} key={weakKey(f)}>
                                <div className={styles.headerCheckbox}>
                                    <Checkbox
                                        checked={allSelected}
                                        onClick={() => {
                                            setAllFacetsInCategory(
                                                f.category.categoryId as string,
                                                f.children?.map(c => c.category.categoryId as string) || [],
                                                !allSelected
                                            )
                                        }}
                                    />
                                </div>
                                <AccordionItem
                                    key={`${index}-${f?.category?.categoryId}`}
                                    className={styles.listItem}
                                    header={
                                        <div className={styles.sidebarCategoryHeader}>
                                            <Text
                                                text={f.category.displayName || ''}
                                                title={f.category.displayName || ''}
                                                highlightText={searchQuery}
                                                overflowEllipsis
                                                fontWeight={f.selected ? 'bold' : 'regular'}
                                                variant="body"
                                            />
                                            {selectedChildren.length > 0 ? <Text
                                                color='grey'
                                            >({selectedChildren.length} {translate('facets.quantitySelected')})</Text> : null}
                                        </div>}
                                >
                                    <ul className={styles.subList}>
                                        {f.children?.sort(sortCategories).map((option, index) => (
                                            <li className={styles.subListItem} key={`${index}-${option.category.categoryId}`}>
                                                <button
                                                    key={index}
                                                    disabled={isFetching}
                                                    className={styles.button}
                                                    onClick={() => setCategoryHierarchyFacet(
                                                        f.category.categoryId as string,
                                                        option.category.categoryId as string
                                                    )}>
                                                    <Checkbox checked={option.selected}></Checkbox>
                                                    <Text
                                                        className={styles.subCategoryText}
                                                        text={option.category.displayName || ''}
                                                        title={option.category.displayName || ''}
                                                        highlightText={searchQuery}
                                                        overflowEllipsis
                                                        fontWeight={option.selected ? 'bold' : 'regular'}
                                                        color='natural700Color'
                                                        variant="bodySmall"
                                                    />
                                                    <Text
                                                        color='natural700Color'
                                                        fontWeight={option.selected ? 'bold' : 'regular'}
                                                        variant="bodySmall">
                                                        ({option?.hits})
                                                    </Text>
                                                </button>
                                            </li>
                                        )
                                        )}
                                    </ul>

                                </AccordionItem>
                            </div>
                        )
                    })
                }
            </Accordion>
        </div>
    );
};
